import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-edit-produit',
  templateUrl: './edit-produit.component.html',
  styleUrls: ['./edit-produit.component.css']
})
export class EditProduitComponent implements OnInit {
  filesToUpload: Array<File> = [];
  video: File = null;

  galeries: any = []

  idProduct: string = ""
  product: any = {
    prd: {
      tags_produit: [],
      prox_cat_produits: []
    }
  }
  allCommercants: any = []
  allCats: any = [];

  listCatsProds: any = []
  catsPrds: any = {}
  listSousCatsProds: any = []
  sousCatsPrd: string = ""
  typeCatsPrds: string = ""
  totalCatsPrd: number = 0

  isStock: Boolean = false

  images = [];
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  types: any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47",
    "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65",
    "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83",
    "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"]
  form: FormGroup;

  constructor(private route: ActivatedRoute,
    private productServices: ProductsService,
    private commercantsService: CommercantsService,
    private fb: FormBuilder,
    private authService: UsersService,) {

    this.isUserStock()
    this.form = fb.group({
      variations: fb.array([])
    })
  }

  async ngOnInit() {
    await this.route.queryParams.subscribe(async (params) => {
      this.idProduct = params.prd;
      await this.getProduct()
      this.setVariationsGroup()
      this.setGaleriesProduct()
    });


    this.listCommercants();
    this.listCats()
    this.listCatsPrds()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  handleVideoInput(event) {
    const fileToUpload = <Array<File>>event.target.files;
    this.video = fileToUpload[0]
  }
  onFileChange(event) {
    const newFfilesToUpload = <Array<File>>event.target.files;
    // this.selectedFiles = event.target.files;
    for (let i = 0; i < newFfilesToUpload.length; i++) {
      this.filesToUpload.push(newFfilesToUpload[i]);
    }

    // console.log("this.filesToUpload : ")
    // console.log(this.filesToUpload)

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.images.push(event.target.result);

          this.myForm.patchValue({
            fileSource: this.images
          });
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  removeSelectedFileUploaded(index) {
    this.images.splice(index, 1);
  }
  removeSelectedFile(index) {
    this.galeries.splice(index, 1);
  }
  setGaleriesProduct() {
    this.product.prd.galerie_photos_produit.forEach(img => {
      this.galeries.push(img)
    });
  }
  setVariationsGroup() {
    const add = this.form.get('variations') as FormArray;
    // console.log("this.product.prd.variations")
    // console.log(this.product.prd.variations)
    this.product.prd.variations.forEach(vari => {
      add.push(this.fb.group({
        label: [vari.label],
        valeurs: [vari.valeurs.join(",")]
      }))
    });
    // console.log(add)
  }
  deleteProductGroup(index: number) {
    const add = this.form.get('variations') as FormArray;
    add.removeAt(index)
  }
  addNewProductGroup() {
    const add = this.form.get('variations') as FormArray;

    add.push(this.fb.group({
      label: [],
      valeurs: []
    }))
    // console.log(add)
  }

  async editProduct() {

    if (this.product.prd.nom_produit != '' && this.product.prd.pro_produit != '' &&
      ((this.product.prd.ref_interne_produit == null && this.catsPrds != '' && this.sousCatsPrd != ''
        && this.typeCatsPrds != '') || this.product.prd.ref_interne_produit != null) &&
      (this.product.prd.tags_produit[0] != '' || this.product.prd.tags_produit[1] != '' ||
        this.product.prd.tags_produit[2] != ''
        || this.product.prd.tags_produit[3] != '' ||
        this.product.prd.tags_produit[4] != '' || this.product.prd.tags_produit[5] != '')
      && this.product.prd.prox_cat_produits.length > 0 &&
      (this.product.prd.prix_achat_produit != '' && this.product.prd.prix_achat_produit > 0)
      && (this.product.prd.prix_produit != '' && this.product.prd.prix_produit >= 0)
      && this.product.prd.description_produit != ''
      && (this.images.length > 0 || this.galeries.length > 0)) {

      const formData: any = new FormData();
      const files: Array<File> = this.filesToUpload;

      // console.log("files");
      // console.log(files);
      for (let i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i]['name']);
      }
      if(this.video != null){
        formData.append("video", this.video, this.video['name']);
      }

      await this.countCatsPrds();

      var variations = []
      this.form.value.variations.forEach(vart => {
        variations.push({
          label: vart.label,
          valeurs: vart.valeurs.split(",")
        })
      });
      var tags_prd = []
      this.product.prd.tags_produit.forEach(tag => {
        tags_prd.push(tag)
      });
      var prox_cat_produits = []
      this.product.prd.prox_cat_produits.forEach(cat => {
        prox_cat_produits.push(cat)
      });
      this.product.prd.variations = JSON.stringify(variations)
      this.product.prd.tags_produit = JSON.stringify(tags_prd)
      this.product.prd.prox_cat_produits = JSON.stringify(prox_cat_produits)
      this.product.prd.galerie_photos_produit = JSON.stringify(this.galeries)

      if (this.product.prd.ref_interne_produit == null) {
        this.product.prd.categorie_produit = this.catsPrds.code_cat_prdt,
          this.product.prd.ss_categorie_produit = this.sousCatsPrd,
          this.product.prd.typecode_prdt = this.typeCatsPrds,
          this.product.prd.ref_interne_produit = this.catsPrds.code_cat_prdt + "-" + this.sousCatsPrd + "-" + this.typeCatsPrds + "-" + this.totalCatsPrd
      }
      if(this.product.prd.ancienprix_produit == null) {
        this.product.prd.ancienprix_produit = ""
      }
      // console.log("product : ")
      // console.log(this.product)

      for (var key in this.product.prd) {
        formData.append(key, this.product.prd[key]);
      }
      if (confirm('Etes vous sûre?')) {
        await this.productServices.editProduct(formData).then((res: any) => {
          // console.log("resssssss editProduct : ")
          // console.log(res)
          if (res) {
            this.product.prd = res
            //location.href = '/produits';
          }
        }).catch((e) => { console.log(e) });
        // location.href = '/produits';
      }
    } else {
      alert('produit est invalide');
    }

  }

  async listCommercants() {
    await this.commercantsService.allCommercants().then((res: any) => {
      if (res) {
        this.allCommercants = res;
      }
    });
  }
  selectCatsPrd(item) {
    // console.log("event.target.value")
    // console.log(item)
    if (item != null) {
      this.listSousCatsPrds(item._id)
    }
    // this.selectedVille = event.target.value;
  }
  async listSousCatsPrds(idCatsPrd) {
    await this.productServices.allSousCatsPrds(idCatsPrd).then((res: any) => {
      // console.log("resssssss : ")
      // console.log(res)
      if (res) {
        this.listSousCatsProds = res;
      }
    });
  }
  async listCats() {
    await this.productServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }
  async listCatsPrds() {
    await this.productServices.allCatsPrds().then((res: any) => {
      // console.log("resssssss : ")
      // console.log(res)
      if (res) {
        this.listCatsProds = res;
      }
    });
  }
  async getProduct() {
    await this.productServices.getProduct(this.idProduct).then((res: any) => {
      // console.log("reeees : ")
      // console.log(res)
      if (res) {
        this.product = res;
      }
    });

    // console.log("this.product.tags_produit")
    // console.log((this.product.prd.tags_produit))
  }
  async countCatsPrds() {
    await this.productServices.countCatsPrds(this.catsPrds.code_cat_prdt, this.sousCatsPrd, this.typeCatsPrds).then((res: any) => {
      if (res) {
        this.totalCatsPrd = res + 1;
      } else {
        this.totalCatsPrd = 1;
      }
    });
  }

}
