import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-categories-produits',
  templateUrl: './categories-produits.component.html',
  styleUrls: ['./categories-produits.component.css']
})
export class CategoriesProduitsComponent implements OnInit {

  listCatsProds: any = []
  catsPrds: string = ""

  constructor(private PrdsServices: ProductsService,) { }

  ngOnInit(): void {
    this.listCatsPrds()
  }

  async addCodificationProduits(label, code) {
    // console.log("catsPrds : ")
    // console.log(this.catsPrds)
    if (label.value != "" && code.value != "" && this.catsPrds!='' && this.catsPrds!='Catégorie parent') {
      if (confirm('Etes vous sûre?')) {
        await this.PrdsServices.addCatPrds(label.value, code.value, this.catsPrds).then(async (res: any) => {
          // console.log("resssssss : ")
          // console.log(res)
          if (res) {
            await this.listCatsPrds()
            label.value = ""
            code.value = ""
          }
        });
      }
    } else {
      alert('Code produit est invalide');
    }

  }

  async listCatsPrds() {
    await this.PrdsServices.allCatsPrds().then((res: any) => {
      // console.log("resssssss : ")
      // console.log(res)
      if (res) {
        this.listCatsProds = res;
      }
    });
  }

}
