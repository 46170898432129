import { Component, OnInit } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-reclamations-commandes',
  templateUrl: './reclamations-commandes.component.html',
  styleUrls: ['./reclamations-commandes.component.css']
})
export class ReclamationsCommandesComponent implements OnInit {

  tagActive = "EN_COURS"
  reclamationsList: any = [];
  lengthRcmsEnCours = 0;
  lengthRcmsSortie = 0;

  constructor(private CmdsServices: CommandesService,) { }

  ngOnInit(): void {
    this.allReclamations("EN_COURS")
    this.getLengthReclamations("EN_COURS")
    this.getLengthReclamations("EN_SORTIE")
  }

  allReclamations(status) {
    this.tagActive = status
    // console.log(cid);
    this.CmdsServices.allReclamations(status).then((d) => {
      if (d) {
        console.log("ddddddddddd : ")
        console.log(d)
        this.reclamationsList = d;
      }
    });
  }

  getLengthReclamations(status) {
    let recsList: any = [];
    this.CmdsServices.allReclamations(status).then((d) => {
      if (d) {
        console.log("ddddddddddd : ")
        console.log(d)
        recsList = d;
        if(status == "EN_COURS") {
          this.lengthRcmsEnCours = recsList.length
        } else {
          this.lengthRcmsSortie = recsList.length
        }
        
      }
    });
  }

}
