<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <div class="ingredient-img margin-right" style="background-image: url('https://assets.marketbey.tn/2BeMarket/categories/{{cat.photo_cat}}'); 
                            background-position: center; background-repeat: no-repeat; 
                            background-size: cover; width: 40px; height: 40px;">
                    </div>
                    <h1>{{cat.label_cat}}</h1>

                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
              </p> -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <!-- <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a class="nav-link active">Tous <span
                                                    class="badge badge-white">fffff</span></a>
                                        </li>

                                    </ul> -->
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                produits :
                                                {{prdsCat.length}}
                                            </h3>
                                            <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                commandes :
                                                {{cat.nbrCmds}}
                                            </h3>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <div class="input-group text-right">
                                                    <!-- <input type="text" class="form-control" id="raisonsocial" name="raisonsocial" placeholder="Raison social"> -->
                                                    <!-- <label for="cat_produit">Catégorie : </label> -->
                                                    <SELECT class="form-control" name="cat_produit[]" id="cat_produit"
                                                        (change)="selectCat($event)">
                                                        <option value="">-- Veuillez choisir une catégorie --</option>
                                                        <option *ngFor="let cat of allCats" [value]="cat._id">
                                                            {{cat.label_cat}}</option>
                                                    </SELECT>
                                                    <button type="submit" (click)="choiceCat()"
                                                        class="btn btn-primary btn-lg btn-icon icon-righ" id="search"
                                                        tabindex="4">
                                                        <!-- Recherche -->
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-12">
                            <div class="card">
                                <!-- <div class="card-header">
                                    <h4>Dernières commandes</h4>
                                    <div class="card-header-action">
                                        <a href="#" class="btn btn-danger">View More <i
                                                class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div> -->
                                <div class="card-body">
                                    <div class="table-responsive table-invoice">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width="150">Photo</th>
                                                    <th width="150">Code interne</th>
                                                    <th>Etat<br>produit</th>
                                                    <th>Nom</th>
                                                    <th>Quantité</th>
                                                    <th>Prix d'achat</th>
                                                    <th>Prix</th>
                                                    <th>Description</th>
                                                    <th width="170" class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let prd of prdsCat">
                                                    <td>
                                                        <!-- <img class="img-responsive"
                                                            src="https://assets.marketbey.tn/produits/{{prd.photo_produit}}" alt="" /> -->

                                                        <div class="ingredient-img"
                                                            style="background-image: url('https://assets.marketbey.tn/2BeMarket/produits/{{prd.photo_produit}}'); 
                                                                            background-position: center; background-repeat: no-repeat; 
                                                                            background-size: cover; width: 100px; height: 100px;">
                                                        </div>
                                                    </td>
                                                    <td>{{prd.ref_interne_produit}}</td>
                                                    <td>etat-{{prd.etat_produit}}</td>
                                                    <td>{{prd.nom_produit}}</td>
                                                    <td>{{prd.stock_reelle}}</td>
                                                    <td>{{prd.prix_achat_produit}}</td>
                                                    <td>{{prd.prix_produit}}</td>
                                                    <td>{{prd.description_produit}}</td>
                                                    <td class="text-right">
                                                        <a title="Ajouter stock" (click)="invaliderProduct(prd._id)"
                                                            *ngIf="isAdmin==true || isValidator"
                                                            class="btn btn-danger btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-minus"></i></a>
                                                        <a title="View" [routerLink]="['/view-produit']"
                                                            [queryParams]="{ prd: prd._id }"
                                                            class="btn btn-warning btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-eye"></i></a>
                                                        <a title="Modifier" [routerLink]="['/edit-produit']"
                                                            *ngIf="isAdmin==true || isValidator || isStock"
                                                            [queryParams]="{ prd: prd._id }"
                                                            class="btn btn-success btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-pencil-alt"></i></a>
                                                        <a title="Supprimer"
                                                            class="btn btn-danger btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</div>