import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';

@Component({
  selector: 'app-products-fournisseurs',
  templateUrl: './products-fournisseurs.component.html',
  styleUrls: ['./products-fournisseurs.component.css']
})
export class ProductsFournisseursComponent implements OnInit {

  idCommercant: string = ""
  prdsComm: any = []
  
  totalPrixAchat = 0.0
  totalPrixVente = 0.0

  constructor(private route: ActivatedRoute,
    private commercantsService: CommercantsService) { }

    ngOnInit(): void {
      this.route.queryParams.subscribe(async (params) => {
        this.idCommercant = params.commercant;
      });
      this.listProduitsCommercants()
    }
  
    async listProduitsCommercants() {
      await this.commercantsService.allPrdsCommercant(this.idCommercant).then((res: any) => {
        if (res) {
          this.prdsComm = res;
        }
      });
      this.prdsComm.forEach(element => {
        this.totalPrixVente += element.prix_produit * element.stock_reelle
        this.totalPrixAchat += element.prix_achat_produit * element.stock_reelle
      });
    }
  
  }
  