<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau fournisseur</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les fournisseur</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter un fournisseur</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form [formGroup]="myForm">
                                            <!-- <div class="row">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Prénom</label>
                                                        <input type="text" class="form-control" name="first_name"
                                                            ngModel #first_name>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="first_name.value==''">Entrez le prénom de
                                                            l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input type="text" class="form-control" name="email_user"
                                                            ngModel #email_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="email_user.value==''">Entrez l'email de l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Nom</label>
                                                        <input type="text" class="form-control" name="last_name" ngModel
                                                            #last_name>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="last_name.value==''">Entrez le prénom de
                                                            l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Num tel</label>
                                                        <input type="number" class="form-control" name="tel_user"
                                                            ngModel #tel_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="tel_user.value==''">Entrez num tel de l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="profiles">Roles</label>
                                                        <select class="form-control" name="profiles"
                                                            [(ngModel)]="profiles" multiple style="height:180px">
                                                            <option *ngFor="let profile of allProfiles"
                                                                [ngValue]="profile">
                                                                {{profile}}</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="profiles.length==0">
                                                            Selectionnez au mois un profile
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Mot de passe</label>
                                                        <input type="password" class="form-control" name="password_user"
                                                            ngModel #password_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="password_user.value==''">Entrez mot de passe
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Confirmer mot de passe</label>
                                                        <input type="password" class="form-control" name="confirme_password_user"
                                                            ngModel #confirme_password_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="confirme_password_user.value=='' || 
                                                            confirme_password_user.value!=password_user.value">Confirmer mot de passe
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="form-row">
                                                <div class="form-group col-md-6 ">
                                                  <label class="col-md-12">Nom Commerçant</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="nom" placeholder="Entrez votre nom">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['nom'].invalid">Entrez votre nom</mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6 ">
                                                  <label class="col-md-12">Societe</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="societe" placeholder="Entrez le nom de votre societe">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['societe'].invalid">Entrez le nom de votre societe </mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6 ">
                                                  <label class="col-md-12">Raison Sociale</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="raisonSociale">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['raisonSociale'].invalid">Entrez le nom de votre raison sociale </mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                  <label class="col-md-12">Email</label>
                                                  <div class="col-md-10">
                                                    <input type="email" class="form-control" formControlName="email">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['email'].invalid">Entrez votre email</mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                  <label class="col-md-12">Mot de passe</label>
                                                  <div class="col-md-10">
                                                    <input type="password" class="form-control" formControlName="password">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['password'].invalid">Entrez votre mot de passe </mat-error>
                                                    
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                  <label class="col-md-12">Confirmer mot de passe</label>
                                                  <div class="col-md-10">
                                                    <input type="password" class="form-control" formControlName="confirmPassword">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['confirmPassword'].invalid">Confirmer votre mot de passe </mat-error>
                                                    
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6 ">
                                                  <label class="col-md-12">Telephone</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="telephone">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['telephone'].invalid">Entrez votre numero de telephone </mat-error>
                                                  </div>
                                                </div> 
                                                <div class="form-group form-group col-md-6 ">
                                                    <label class="col-md-12">Adresse</label>
                                                    <div class="col-md-10">
                                                      <input type="text" class="form-control" formControlName="adresse">
                                                      <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['adresse'].invalid">Entrez le nom de votre Pays </mat-error>
                                                    </div>
                                                  </div>
                                                <div class="form-group col-md-6 ">
                                                  <label class="col-md-12">Ville</label>
                                                  <div class="col-md-10">
                                                    <select class="form-control selectric" formControlName="ville">
                                                      <option *ngFor="let ville of villes" value="{{ville}}" >{{ville}}</option>
                                                    </select>
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['ville'].invalid">Choisissez votre ville </mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group form-group col-md-6 ">
                                                  <label class="col-md-12">Pays</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="pays">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['pays'].invalid">Entrez le nom de votre Pays </mat-error>
                                                  </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                  <label class="col-md-12">Code Postal</label>
                                                  <div class="col-md-10">
                                                    <input type="text" class="form-control" formControlName="codePostal">
                                                    <mat-error style="color: red;font-weight: bold;" *ngIf="myForm.controls['codePostal'].invalid">Entrez votre code postal </mat-error>
                                                  </div>
                                                </div>
                                              </div>
                                                
                                            <div class="form-group">
                                                <button type="button"
                                                    (click)="addCommercant()"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter fournisseur
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>