<div>
  <div class="main-wrapper">
    <app-top></app-top>
    <app-menu></app-menu>
    <!-- Main Content -->
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <h1>Détails d'une commande</h1>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active"><a href="#">Dashboard</a></div>
            <div class="breadcrumb-item active"><a href="#">Commandes</a></div>
            <div class="breadcrumb-item">Détails Commande</div>
          </div>
        </div>

        <div class="section-body">
          <div class="invoice">
            <div id="commande" #commande>
              <div class="row">
                <div class="col-lg-12">
                  <div class="invoice-title">
                    <h2>Commande</h2>
                    <div class="invoice-number"># {{cmd.ref_cmd}}</div>

                  </div>
                  <hr>
                  <div class="row">

                    <div class="col-md-6">
                      <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                        [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                    <div class="col-md-6 text-md-right">
                      <address>
                        <strong>Client:</strong><br>
                        {{cmd.facturation.nom}}<br>
                        {{cmd.facturation.email}}<br>
                        {{cmd.facturation.numtel}}<br>
                        {{cmd.facturation.adresse}}<br />
                      </address>
                      <address>
                        <strong>Date de commande:</strong><br>
                        {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br>
                        <div *ngIf="cmd.etat_cmd" class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                          {{statusCmds[cmd.etat_cmd]?.label}}
                        </div>
                      </address>
                    </div>

                  </div>

                </div>
              </div>
              <div class="clearfix"></div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <div class="section-title">Détails de la commande</div>
                  <div class="table-responsive">
                    <table class="table table-striped table-hover table-md">
                      <tr>
                        <th data-width="40">#</th>
                        <th>Photo Prdt</th>
                        <th>Nom Prdt</th>
                        <th>Fournisseur</th>
                        <th class="text-right">Prix unitaire</th>
                        <th class="text-right">Quantite</th>
                        <th class="text-right">Montant</th>
                      </tr>
                      <tr *ngFor="let prd of cmd.basket.prdts, let index = index">
                        <td>{{index+1}}</td>
                        <td><img alt="image" src="{{baseURL}}/{{prd.image}}" width="100" data-toggle="title" title="">
                        </td>
                        <td>{{prd.nom}}
                          <div *ngIf="prd.variation_choisi!=null">
                            <span *ngFor="let variation of prd?.variation_choisi | objNgFor">
                              <div><b> {{variation.value}} </b></div>
                            </span>
                          </div>
                        </td>
                        <td>{{prd.pro_details_produit.raisonsocial_pro}}, {{prd.pro_details_produit.tel_pro}},
                          {{prd.pro_details_produit.adresse_pro}}, {{prd.pro_details_produit.ville_pro}}</td>
                        <td class="text-center">{{prd.prix_unitaire}} TND</td>
                        <td class="text-center">{{prd.quantite}}</td>
                        <td class="text-right">{{prd.stotal}} TND</td>

                      </tr>
                    </table>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-8">
                      <div class="section-title">Mode de livraison</div>
                      <p class="section-lead">
                        {{cmd.livraison_cmd.moyen_livraison}}
                        <br />{{cmd.livraison_cmd.pcollect_details.adresse_pcollect}}
                      </p>
                    </div>
                    <div class="col-lg-4 text-right">
                      <div class="invoice-detail-item">
                        <div class="invoice-detail-name">Prix des article(s): {{cmd.total_ca_cmd | number:'0.3'}} TND</div>
                        <div class="invoice-detail-name">Frais de livraison:
                          {{cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</div>
                        <div class="invoice-detail-value">Montant total:
                          {{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</div>
                        <div class="invoice-detail-value">Poids: {{cmd.weight_cmd}} g</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
            <hr>
            <div class="text-md-right">
              <div class="float-lg-left mb-lg-0 mb-3">
                <a [routerLink]="[ '/commandes']" class="btn btn-danger btn-icon icon-left"><i
                    class="fas fa-arrow-left"></i>
                  Retour</a>
              </div>
              <button *ngIf="cmd.etat_cmd=='pending'" (click)="updateStatusCmd('validated')"
                class="btn btn-primary btn-icon icon-left"><i class="fas fa-check"></i> Valider la commande</button>
              &nbsp;
              <!-- <button *ngIf="cmd.etat_cmd=='validated'" (click)="updateStatusCmd('preparing')"
                class="btn btn-primary btn-icon icon-left"><i class="fas fa-check"></i> Préparer</button> &nbsp; -->
              <!-- <button printSectionId="commande" ngxPrint class="btn btn-warning btn-icon icon-left"><i class="fas fa-print"></i> -->
              <button (click)="generatePDF()" class="btn btn-warning btn-icon icon-left"><i class="fas fa-print"></i>
                Imprimer</button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <h4>Détail de la commande "<strong>{{cmd.ref_cmd}}</strong>"</h4>
                  <div class="card-header-action">
                    <a href="#" class="btn btn-danger">View More <i class="fas fa-chevron-right"></i></a>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive table-invoice">
                    <table class="table table-striped">
                      <tr>
                        <!-- <th>Invoice ID</th> -->
                        <th>Status</th>
                        <th>Description</th>
                        <th>Date de l'action</th>
                      </tr>
                      <tr>
                        <!-- <td><a href="#">INV-87239</a></td> -->
                        <td>
                          <div class="badge badge-warning">Créé</div>
                        </td>
                        <td class="font-weight-600">Par {{cmd.facturation.nom}}</td>
                        <td>{{cmd.date_creation_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                      <tr *ngIf="cmd.transport_topcollect!=null">
                        <!-- <td><a href="#">INV-48574</a></td> -->
                        <td>
                          <div class="badge badge-{{statusCmds['transporting'].color}}">
                            {{statusCmds['transporting'].label}}</div>
                        </td>
                        <td class="font-weight-600">Par {{cmd.transport_topcollect.transporter_name}}</td>
                        <td>{{cmd.transport_topcollect.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                      <tr *ngIf="cmd.pcollect_transport!=null">
                        <!-- <td><a href="#">INV-76824</a></td> -->
                        <td>
                          <div class="badge badge-{{statusCmds['arrived'].color}}">{{statusCmds['arrived'].label}}</div>
                        </td>
                        <td class="font-weight-600">Par {{cmd.pcollect_transport.pcollect_name}}</td>
                        <td>{{cmd.pcollect_transport.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                      <tr *ngIf="cmd.payed_cmd==true">
                        <!-- <td><a href="#">INV-84990</a></td> -->
                        <td>
                          <div class="badge badge-{{statusCmds['delivered'].color}}">{{statusCmds['delivered'].label}}
                          </div>
                        </td>
                        <td class="font-weight-600">Commande livré au client "{{cmd.facturation.nom}}"</td>
                        <td>{{cmd.date_payed_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                      <tr *ngIf="cmd.date_returned_cmd!=null">
                        <!-- <td><a href="#">INV-87320</a></td> -->
                        <td>
                          <div class="badge badge-{{statusCmds['returned'].color}}">{{statusCmds['returned'].label}}
                          </div>
                        </td>
                        <td class="font-weight-600"
                          *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id==null">
                          Par
                          {{cmd?.transport_affect[cmd?.transport_affect?.length-2]?.transporter_name}}<br />{{cmd.msg_returned_cmd}}
                        </td>
                        <td class="font-weight-600"
                          *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id!=null">
                          Par
                          {{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}<br />{{cmd.msg_returned_cmd}}
                        </td>
                        <td>{{cmd.date_returned_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card" *ngIf="cmd?.appel_client.length>0">
                <div class="card-header">
                  <h4>Détail des appels de la commande "<strong>{{cmd.ref_cmd}}</strong>"</h4>
                  <div class="card-header-action">
                    <a href="#" class="btn btn-danger">{{cmd.appel_client.length}}</a>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive table-invoice">
                    <table class="table table-striped">
                      <tr>
                        <th>Indice</th>
                        <th>Transporteur</th>
                        <th>Date de l'appel</th>
                      </tr>
                      <tr *ngFor="let appel of cmd.appel_client, let index = index">
                        <td>
                          {{index+1}}
                        </td>
                        <td class="font-weight-600">Par {{appel.transporter_name}}</td>
                        <td>{{appel.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card card-hero">
                <div class="card-header">
                  <div class="card-icon">
                    <i class="far fa-question-circle"></i>
                  </div>
                  <h4>Ref: {{cmd.ref_cmd}}</h4>
                  <div class="card-description badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                    {{statusCmds[cmd.etat_cmd]?.label}}</div>
                </div>
                <div class="card-body p-0">
                  <div class="tickets-list">
                    <a href="#" class="ticket-item" *ngIf="cmd.payed_cmd==true">
                      <div class="ticket-title">
                        <h4>Commande livré au client "<strong>{{cmd.facturation.nom}}</strong>"</h4>
                      </div>
                      <div class="ticket-info">
                        <div>Payé à </div>
                        <div class="bullet"></div>
                        <div>{{cmd.date_payed_cmd | date:'MMM d, y h:mm:ss a'}}</div>
                      </div>
                      <div class="ticket-info">
                        <div>Relivré à </div>
                        <div class="bullet"></div>
                        <div>{{cmd.date_relivered_cmd | date:'MMM d, y h:mm:ss a'}}</div>
                      </div>
                    </a>
                    <a href="#" class="ticket-item"
                      *ngFor="let transport of cmd?.transport_affect?.slice()?.reverse(), let index = index">
                      <div class="ticket-title">
                        <h4 *ngIf="index==0 && cmd.payed_cmd==false">Commande pris en charge</h4>
                        <h4 *ngIf="index>0 || index==0 && cmd.payed_cmd==true">Affecté à</h4>
                      </div>
                      <div class="ticket-info">
                        <div>{{transport.transporter_name}}</div>
                        <div class="bullet"></div>
                        <div class="text-primary">{{transport.date_accept | date:'MMM d, y h:mm:ss a'}}</div>
                      </div>
                    </a>
                    <a href="#" class="ticket-item">
                      <div class="ticket-title">
                        <h4>Commande Créé</h4>
                      </div>
                      <div class="ticket-info">
                        <div>{{cmd.facturation.email}}</div>
                        <div class="bullet"></div>
                        <div>{{cmd.date_creation_cmd | date:'MMM d, y h:mm:ss a'}}</div>
                      </div>
                    </a>

                    <!-- <a routerLink="history_cmd" class="ticket-item ticket-more">
                      Voir tous<i class="fas fa-chevron-right"></i>
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>