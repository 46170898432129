import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-view-reclamation',
  templateUrl: './view-reclamation.component.html',
  styleUrls: ['./view-reclamation.component.css']
})
export class ViewReclamationComponent implements OnInit {

  cmdID: string = '';
  reclamation: any = {};

  constructor(
    private route: ActivatedRoute,
    private CmdsServices: CommandesService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchReclamation();
      // console.log(this.cmd);
    });
  }

  _fetchReclamation() {
    // console.log(cid);
    this.CmdsServices.getOneReclamation(this.cmdID).then((rec) => {
      console.log("rec : ")
      console.log(rec)
      if (rec) {
        this.reclamation = rec;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

}
