import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-add-user-mb',
  templateUrl: './add-user-mb.component.html',
  styleUrls: ['./add-user-mb.component.css']
})
export class AddUserMbComponent implements OnInit {

  allProfiles: any = ["simple_user", "stock", "validator", "admin"]
  profiles: any = []

  constructor(private usersService: UsersService) { }

  ngOnInit(): void {
  }

  async addUserMB(first_name, email_user, last_name, tel_user,
    password_user, confirme_password_user) {
    if (last_name.value != '' && first_name.value != '' && tel_user.value != '' &&
      email_user.value != '' && password_user.value != '' &&
      password_user.value == confirme_password_user.value && this.profiles.length > 0) {
      var newUserMB = {
        nom: last_name.value,
        prenom: first_name.value,
        numtel: tel_user.value,
        username: email_user.value,
        password: password_user.value,
        profiles: this.profiles
      }
      await this.usersService.addUserMB(newUserMB).then((res: any) => {
        if (res) {
          alert('Utilisateur est ajouté avec succés');
          location.href = '/teams-mb';
        }
      }).catch((e) => { console.log(e) });
    } else {
      alert('Données utilisateurs est invalide');
    }

  }

}
