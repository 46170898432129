<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Ajouter une reclamation pour la commande "{{cmd.ref_cmd}}"</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">ajouter reclamation</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <!-- <div class="budget-price justify-content-center" *ngIf="cmdsList.length === 0">
                                    <h3>Aucune commande affecté/livré au transporteur
                                        "{{userTracking?.nom_prenom_tracking}}" </h3>
                                </div> -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div class="div-form">
                                            <form class="needs-validation" action="" method="post"
                                                onsubmit="return false;" (onsubmit)="addReclamation(comment,autre);">
                                                <div class="form-group">
                                                    <label for="ref">Référence</label>
                                                    <input id="ref" type="text" disabled class="form-control"
                                                        tabindex="1" value="{{cmd.ref_cmd}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="ref">Client</label>
                                                    <input id="ref" type="text" disabled class="form-control"
                                                        tabindex="1" value="{{cmd.facturation.nom}}">
                                                    <input id="ref" type="text" disabled class="form-control"
                                                        tabindex="1" value="{{cmd.facturation.numtel}}">
                                                    <input id="ref" type="text" disabled class="form-control"
                                                        tabindex="1" value="{{cmd.facturation.adresse}}">
                                                    <input id="ref" type="text" disabled class="form-control"
                                                        tabindex="1" value="{{cmd.facturation.ville}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="raison">Raison</label>
                                                    <select class="form-control selectric" 
                                                    [ngClass]="{ 'is-invalid': submitted && selectedRaison!='Selectionnez une raison' }"
                                                        (change)="selectChangeRaison($event)">
                                                        <option [value]="raison" *ngFor="let raison of raisons" #raison
                                                            ngModel>{{raison}}</option>
                                                    </select>
                                                    <div class="invalid-feedback"
                                                        *ngIf="selectedRaison!='Selectionnez une raison'">
                                                        Il faut selectionner la raison *
                                                    </div>
                                                    <textarea [hidden]="isAutre" type="text" id="autre" name="autre"
                                                        class="md-textarea md-textarea-auto form-control selectric"
                                                        #autre ngModel></textarea>
                                                </div>
                                                <div class="form-group">
                                                    <label for="raison">Commentaire</label>
                                                    <textarea type="text" id="comment" name="comment"
                                                        class="md-textarea md-textarea-auto form-control selectric"
                                                        #comment ngModel></textarea>
                                                </div>

                                                <div class="col-12">
                                                    <div class="float-right">
                                                        <div class="input-group">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-danger" type="submit"
                                                                    (click)="addReclamation(comment,autre)"><i
                                                                        class="fas fa-angellist">
                                                                        Créer une réclamation</i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>