import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CommandesComponent } from './pages/commandes/commandes.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DetailsCommandeComponent } from './pages/details-commande/details-commande.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { FactureComponent } from './pages/facture/facture.component';
import { CommandesPretesComponent } from './pages/commandes-pretes/commandes-pretes.component';
import { TrackingUsersComponent } from './pages/tracking-users/tracking-users.component';
  import { AffectationDialogComponent } from './components/shared/dialogs/affectation-dialog/affectation-dialog.component';
  import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommandesUsersComponent } from './pages/commandes-users/commandes-users.component';
import { ReclamationsCommandesComponent } from './pages/reclamations/reclamations-commandes/reclamations-commandes.component';
import { AddReclamationComponent } from './pages/reclamations/add-reclamation/add-reclamation.component';
import { ValidateReclamationComponent } from './pages/reclamations/validate-reclamation/validate-reclamation.component';
import { ViewReclamationComponent } from './pages/reclamations/view-reclamation/view-reclamation.component';
import { ValidationDialogComponent } from './components/shared/dialogs/validation-dialog/validation-dialog.component';
import { AddCodePromoComponent } from './pages/promotions/add-code-promo/add-code-promo.component';
import { AllCodesPromoComponent } from './pages/promotions/all-codes-promo/all-codes-promo.component';
import { ObjNgForPipe } from './pipes/obj-ng-for.pipe';
import { InventaireComponent } from './pages/inventaire/inventaire.component';
import { CommandesWebsiteComponent } from './pages/commandes-website/commandes-website.component';
import { CommandesFacebookComponent } from './pages/commandes-facebook/commandes-facebook.component';
import { FournisseursComponent } from './pages/fournisseurs/fournisseurs/fournisseurs.component';
import { AddFournisseurComponent } from './pages/fournisseurs/add-fournisseur/add-fournisseur.component';
import { ProductsFournisseursComponent } from './pages/fournisseurs/products-fournisseurs/products-fournisseurs.component';
import { AvatarModule } from 'ngx-avatar';
import { SoldProduitsComponent } from './pages/produits/sold-produits/sold-produits.component';
import { ProduitsCategoryComponent } from './pages/produits/produits-category/produits-category.component';
import { RecoveryProduitsComponent } from './pages/produits/recovery-produits/recovery-produits.component';
import { ProduitsComponent } from './pages/produits/produits/produits.component';
import { AddProduitComponent } from './pages/produits/add-produit/add-produit.component';
import { ViewProduitComponent } from './pages/produits/view-produit/view-produit.component';
import { AddStockProductComponent } from './components/shared/dialogs/add-stock-product/add-stock-product.component';
import { EditProduitComponent } from './pages/produits/edit-produit/edit-produit.component';
import { PendingProduitComponent } from './pages/produits/pending-produit/pending-produit.component';
import { CategoriesComponent } from './pages/categories/categories/categories.component';

import { AuthGuard } from './services/guard/auth.guard';
import { TeamsMBComponent } from './pages/teamsMB/teams-mb/teams-mb.component';
import { AddUserMbComponent } from './pages/teamsMB/add-user-mb/add-user-mb.component';
import { CommandesFournisseursComponent } from './pages/fournisseurs/commandes-fournisseurs/commandes-fournisseurs.component';
import { ValidateProduitComponent } from './pages/produits/validate-produit/validate-produit.component';
import { UsersComponent } from './pages/users/users/users.component';
import { CommandesCategoriesComponent } from './pages/categories/commandes-categories/commandes-categories.component';
import { InvalidFournisseurComponent } from './pages/fournisseurs/invalid-fournisseur/invalid-fournisseur.component';
import { ValidateFournisseurComponent } from './components/shared/dialogs/validate-fournisseur/validate-fournisseur.component';
import { DetailsFournisseurComponent } from './pages/fournisseurs/details-fournisseur/details-fournisseur.component';
import { AddAccountComponent } from './components/shared/dialogs/add-account/add-account.component';
import { AddCommentsComponent } from './components/shared/dialogs/add-comments/add-comments.component';
import { MyCommandesComponent } from './pages/my-commandes/my-commandes.component';
import { CategoriesProduitsComponent } from './pages/categories/categories-produits/categories-produits.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { EditCategoryComponent } from './pages/categories/edit-category/edit-category.component';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
    vertical: {
      position: 'top',
    },
  },
  theme: 'material',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CommandesComponent,
    DashboardComponent,
    MenuComponent,
    TopComponent,
    FooterComponent,
    DetailsCommandeComponent,
    FactureComponent,
    CommandesPretesComponent,
    TrackingUsersComponent,
    AffectationDialogComponent,
    CommandesUsersComponent,
    ReclamationsCommandesComponent,
    AddReclamationComponent,
    ValidateReclamationComponent,
    ViewReclamationComponent,
    ValidationDialogComponent,
    AddCodePromoComponent,
    AllCodesPromoComponent,
    ObjNgForPipe,
    InventaireComponent,
    CommandesWebsiteComponent,
    CommandesFacebookComponent,
    FournisseursComponent,
    AddFournisseurComponent,
    ProductsFournisseursComponent,
    SoldProduitsComponent,
    ProduitsCategoryComponent,
    RecoveryProduitsComponent,
    ProduitsComponent,
    AddProduitComponent,
    ViewProduitComponent,
    AddStockProductComponent,
    EditProduitComponent,
    PendingProduitComponent,
    CategoriesComponent,
    TeamsMBComponent,
    AddUserMbComponent,
    CommandesFournisseursComponent,
    ValidateProduitComponent,
    UsersComponent,
    CommandesCategoriesComponent,
    InvalidFournisseurComponent,
    ValidateFournisseurComponent,
    DetailsFournisseurComponent,
    AddAccountComponent,
    AddCommentsComponent,
    MyCommandesComponent,
    CategoriesProduitsComponent,
    AddCategoryComponent,
    EditCategoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    QRCodeModule,
    NgxPrintModule,
    MatDialogModule ,
    NotifierModule.withConfig(customNotifierOptions),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
