<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau produit</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">

                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter un produit</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Nom du produit</label>
                                                        <input type="text" class="form-control" name="nom_produit" disabled
                                                            [value]="product.prd?.nom_produit">
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Fournisseur</label>
                                                        <input type="text" class="form-control" name="nomresponsable_comercant" disabled
                                                            [value]="product.commercant?.nomresponsable_comercant">
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Reference du produit</label>
                                                        <input type="text" class="form-control" name="ref_interne_produit" disabled
                                                            [value]="product.prd?.ref_interne_produit!=null ? product.prd?.ref_interne_produit : ''">
                                                    </div>
                                                </div>

                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="code_barre">Code à barre (Optionnel)</label>
                                                        <input type="text" class="form-control" disabled
                                                            name="codeabrre_produit" [value]="product.prd?.codeabrre_produit">
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-md-12">Mots clef</label>
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <!-- <div class="col-md-4" *ngFor="let tag_prd of tags_prdt; let index = index">
                                                                    <div class="form-group">
                                                                        <input type="text" [(ngModel)]="tags_prdt[index]" name="index"
                                                                            placeholder="Mot clef"
                                                                            class="form-control">
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd1" [value]="product.prd?.tags_produit[0]!=null ? product.prd?.tags_produit[0] : ''" disabled 
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd2" [value]="product.prd?.tags_produit[1]!=null ? product.prd?.tags_produit[1] : ''" disabled 
                                                                        class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd3" [value]="product.prd?.tags_produit[2]!=null ? product.prd?.tags_produit[2] : ''" disabled 
                                                                        class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd4" [value]="product.prd?.tags_produit[3]!=null ? product.prd?.tags_produit[3] : ''" disabled 
                                                                        class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd5" [value]="product.prd?.tags_produit[4]!=null ? product.prd?.tags_produit[4] : ''" disabled 
                                                                        class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd6" [value]="product.prd?.tags_produit[5]!=null ? product.prd?.tags_produit[5] : ''" disabled 
                                                                        class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <textarea name="tags" class="form-control" rows="5"></textarea> -->
                                                        </div>
                                                        <!-- <small><i>Un mot clef par ligne</i></small> -->
                                                    </div>
                                                </div>

                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="last_name">Catégorie</label>
                                                        <!-- <select class="form-control"
                                                             style="height:180px" multiple>
                                                            <option *ngFor="let cat of product.prd?.prox_cat_produits" [value]="cat">
                                                                {{cat}}</option>
                                                        </select> -->

                                                        <select class="form-control" name="cats_produit" disabled
                                                            [(ngModel)]="product.prd.prox_cat_produits" multiple
                                                            style="height:180px">
                                                            <!-- <option value="-"></option> -->
                                                            <option *ngFor="let cat of allCats" [ngValue]="cat._id">
                                                                {{cat.label_cat}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="prix_achat_produit">Prix d'achat du produit</label>
                                                    <input id="prix_achat_produit" type="number" class="form-control"
                                                        name="prix_achat_produit"[value]="product.prd?.prix_achat_produit" disabled>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="prix_produit">Prix du produit</label>
                                                    <input id="prix_produit" type="number" class="form-control"
                                                        name="prix_produit" [value]="product.prd?.prix_produit" disabled>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="ancienprix_produit">Ancien Prix (prix barré)</label>
                                                    <input id="ancienprix_produit" type="number" class="form-control"
                                                        name="ancienprix_produit" [value]="product.prd?.ancienprix_produit" disabled>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="stock_reelle">Quantité du produit</label>
                                                    <input id="stock_reelle" type="number" class="form-control"
                                                        name="stock_reelle" [value]="product.prd?.stock_reelle" disabled>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="seuil_stock">Seuil de stock</label>
                                                    <input id="seuil_stock" type="number" class="form-control"
                                                        name="seuil_stock" [value]="product.prd?.seuil_stock" disabled>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="isDelivery" disabled
                                                            class="custom-control-input" id="isDelivery"
                                                            [checked]="product.prd?.isDelivery">
                                                        <label class="custom-control-label"
                                                            for="isDelivery">Livraison gratuite</label>
                                                    </div>

                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="tested" disabled
                                                            class="custom-control-input" id="tested" [checked]="product.prd?.tested">
                                                        <label class="custom-control-label" for="tested">Testé pour
                                                            vous</label>
                                                    </div>

                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="quantiteLimite" disabled
                                                            class="custom-control-input" id="quantiteLimite"
                                                            [checked]="product.prd?.quantiteLimite">
                                                        <label class="custom-control-label"
                                                            for="quantiteLimite">Quantité limitée</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-6">
                                                    <label for="description">Description</label>
                                                    <textarea disabled
                                                        [value]="product.prd?.description_produit" class="form-control" style="height: 120px;"></textarea>
                                                </div>
                                                <div class="form-group col-6">
                                                    <label for="commentaire">Commentaire</label>
                                                    <textarea disabled
                                                    [value]="product.prd?.comment_produit" class="form-control" style="height: 120px;"></textarea>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-3 border-variation"
                                                    *ngFor="let productGroup of product.prd?.variations;let i = index"
                                                    >

                                                    <label for="name_variation">Nom de la variation</label>
                                                    <input type="text" class="form-group form-control" [value]="productGroup.label">


                                                    <label for="valeurs_variation">Valeurs</label>
                                                    <input type="text" class="form-group form-control" [value]="productGroup.valeurs">

                                                    <!-- <div class="form-group float-right">
                                                        <button type="button" (click)="deleteProductGroup(i)"
                                                            class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                                                class="fas fa-trash"> </i></button>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="position-relative row form-group">
                                                    <button type="button" (click)="addNewProductGroup()"
                                                        class="mt-1 btn btn-warning"><i class="pe-7s-plus"></i>
                                                        Ajouter une variation</button>
                                                </div>
                                            </div> -->

                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label for="uploads">Multiple Upload</label>
                                                    <div class="Neon Neon-theme-dragdropbox">

                                                        <div class="Neon-input-dragDrop">
                                                            <div class="Neon-input-inner">
                                                                <div class="Neon-input-icon" *ngIf="product.prd?.galerie_photos_produit.length==0">
                                                                    <i class="fa fa-file-image-o"></i>
                                                                </div>

                                                                <!-- <div class="col-2">
                                                                    <div class="avatar-item mb-0">
                                                                      <img alt="image" src="../assets/img/avatar/avatar-5.png" class="img-fluid" data-toggle="tooltip" title="Alfa Zulkarnain">
                                                                      <div class="avatar-badge" title="Editor" data-toggle="tooltip"><i class="fas fa-wrench"></i></div>
                                                                    </div>
                                                                  </div> -->

                                                                <div class="col-lg-12  Neon-input-icon"
                                                                    *ngIf="product.prd?.galerie_photos_produit.length>0">
                                                                    <div class="col-2"
                                                                        *ngFor='let url of product.prd?.galerie_photos_produit; let index = index'>
                                                                        <img src="https://assets.marketbey.tn/2BeMarket/produits/{{url}}" height="120" width="120px">
                                                                        <!-- <div class="avatar-badge" title="Editor"
                                                                            (click)="removeSelectedFile(index)"
                                                                            data-toggle="tooltip"><i
                                                                                class="fas fa-times icon"></i>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="Neon-input-text">
                                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                                </div> -->
                                                                <div>
                                                                    <!-- <input
                                                                        style="z-index: 999; opacity: 0; height: 60px; position: absolute; right: 0px; 
                                                                            left: 0px; margin-right: auto; margin-left: auto;"
                                                                        name="file" id="file" #file accept="image/*"
                                                                        type="file" multiple
                                                                        (change)="onFileChange($event)"> -->
                                                                    <!-- <a class="Neon-input-choose-btn blue">Browse
                                                                        Files</a> -->
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="form-group">
                                                <button type="button"
                                                    (click)="addProduct(nom_produit, code_barre, prix_achat_produit, prix_produit, ancienprix_produit,
                                                                    stock_reelle, seuil_stock, description, commentaire,
                                                                    tag_prd1, tag_prd2, tag_prd3, tag_prd4, tag_prd5, tag_prd6)"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter produit
                                                </button>
                                            </div> -->
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>