import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {

  imageUrl: string = '';
  fileToUpload: File = null;

  constructor(private PrdsServices: ProductsService) { }

  ngOnInit(): void {
  }

  addCategory(label_category, color_category, order_category, code) {
    if (label_category.value != "" && color_category.value != "" && order_category.value >= 0
      && order_category.value != "" && this.imageUrl != "" && code.value!='') {

      const formData: any = new FormData();

      formData.append("photoCat", this.fileToUpload, this.fileToUpload['name']);

      var category = {
        label_cat: label_category.value,
        order_cat: order_category.value,
        color_cat: color_category.value,
        code_cat: code.value
      }
      for (var key in category) {
        formData.append(key, category[key]);
      }

      // if (true) {
      if (confirm('Etes vous sûre?')) {
        this.PrdsServices.addCat(formData).then((res: any) => {
          // console.log("ressssss add cat : ")
          // console.log(res)
          if (res == true) {
            location.href = '/categories';
          } else {
            alert(res.msg);
          }
        });
      }
    } else {
      alert('Catégorie est invalide');
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

}
