<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Détails de la reclamation - commande {{reclamation.commande.ref_cmd}}</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">valider reclamation</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <!-- <div class="budget-price justify-content-center" *ngIf="cmdsList.length === 0">
                                    <h3>Aucune commande affecté/livré au transporteur
                                        "{{userTracking?.nom_prenom_tracking}}" </h3>
                                </div> -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <div class="div-form">
                                            <form class="needs-validation" action="" method="post"
                                                onsubmit="return false;">
                                                <div class="form-group">
                                                    <label for="ref">Référence</label>
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1"
                                                        value="{{reclamation.commande.ref_cmd}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="client">Client</label>
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1" value="{{reclamation.commande.facturation.nom}}">
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1"
                                                        value="{{reclamation.commande.facturation.numtel}}">
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1"
                                                        value="{{reclamation.commande.facturation.adresse}}">
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1" value="{{reclamation.commande.facturation.ville}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <input type="text" disabled class="form-control" 
                                                        tabindex="1" 
                                                        value="{{reclamation.status_reclamation}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="raison">Raison</label>
                                                    <input  type="text" disabled class="form-control"
                                                        tabindex="1"
                                                        value="{{reclamation.raison_reclamation}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="solution">Solution</label>
                                                    <input type="text" disabled class="form-control"
                                                        tabindex="1"
                                                        value="{{reclamation.solution_reclamation}}">
                                                </div>
                                                <div class="form-group">
                                                    <label for="comment">Commentaire</label>
                                                    <textarea type="text" rows="5"
                                                        class="md-textarea md-textarea-auto form-control selectric">
                                                        {{reclamation.commentaire_reclamation}}</textarea>
                                                </div>
                                                <!-- <div class="col-12">
                                                    <div class="float-right">
                                                        <div class="input-group">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-danger" type="submit"
                                                                    (click)="validerReclamation(reclamation._id,comment)"><i
                                                                        class="fas fa-angellist">
                                                                        Vlider la réclamation</i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </form>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>