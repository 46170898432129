import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  allUsers: any = []

  constructor(private usersService: UsersService) { }

  ngOnInit(): void {
this.listUsers()
  }

  async listUsers() {
    await this.usersService.allUsers().then((res: any) => {
      if (res) {
        this.allUsers = res;
      }
    });
  }

}
