<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Code produit</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter la codification du produit</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form action="" method="post" enctype="multipart/form-data">

                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label>Libelé : </label>
                                                        <input type="text" class="form-control" name="label"
                                                            ngModel #label>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="label.value==''">Entrez label de la
                                                            catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label>Code : </label>
                                                        <input type="text" class="form-control" name="code"
                                                            ngModel #code>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="code.value==''">Entrez code de la
                                                            catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label>Catégorie parent : </label>
                                                        <select class="form-control selectric" name="catsPrds"
                                                            [(ngModel)]="catsPrds">
                                                            <option value="">Catégorie parent</option>
                                                            <option *ngFor="let catPrd of listCatsProds"
                                                                [ngValue]="catPrd._id">{{catPrd.nom_cat_prdt}}
                                                                ({{catPrd.code_cat_prdt}})</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="catsPrds=='' || catsPrds=='Catégorie parent'">Entrez code de la
                                                            catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="button" (click)="addCodificationProduits(label, code)"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter code produit
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>