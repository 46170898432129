import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddStockProductComponent } from 'src/app/components/shared/dialogs/add-stock-product/add-stock-product.component';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-produits',
  templateUrl: './produits.component.html',
  styleUrls: ['./produits.component.css']
})
export class ProduitsComponent implements OnInit {

  prdsListAll: any = [];

  isAdmin: Boolean = false
  isSimpleUser: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  constructor(private PrdsServices: ProductsService,
    private dialog: MatDialog,
    private authService: UsersService,) {
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator()
  }

  async ngOnInit() {
    this.listProduits();

    // this.isAdmin = await this.authService.isAdmin()
    // this.isSimpleUser = await this.authService.isSimpleUser()
    // this.isStock = await this.authService.isStock()
    // this.isValidator = await this.authService.isValidator()
  }

  async listProduits() {
    await this.PrdsServices.allPrds().then((res: any) => {
      if (res) {
        this.prdsListAll = res;
      }
    });
  }

  invaliderProduct(idPrd) {
    if (confirm('Etes vous sûre?')) {
      this.PrdsServices.invalidPrd(idPrd).then((res: any) => {
        // console.log("invaliderProduct res")
        // console.log(res)
        if (res) {
          location.href = '/pending-produit';
        }
      });
    }
  }

  async addStock(idPrd) {
    const confirmDialog = this.dialog.open(AddStockProductComponent, {
      data: {
        idPrd: idPrd,
        stockPrd: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        if (data.stockPrd == null || data.stockPrd == "") {
          alert('Quantité de stock produit est invalide');
        } else {
          this.PrdsServices.addStockProduct(idPrd, data.stockPrd).then((res) => {
            if (res) {

            }
            // else {
            //   // console.log("erreur");
            //   this.notif.notify('error', "Une erreur s'est produite");
            // }
          });
        }

      }
    });

  }
  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
