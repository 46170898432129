import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {

  idCat: string = ''
  cat: any = {}
  ancienLabelCat: string = ""

  imageUrl: string = '';
  fileToUpload: File = null;


  constructor(private route: ActivatedRoute,
    private PrdsServices: ProductsService) { }

  async ngOnInit() {
    await this.route.queryParams.subscribe(async (params) => {
      this.idCat = params.cat;
      await this.getCat()
      // this.setVariationsGroup()
      // this.setGaleriesProduct()
    });
  }

  async getCat() {
    await this.PrdsServices.getCat(this.idCat).then((res: any) => {
      console.log(res)
      if (res) {
        this.cat = res;
        this.ancienLabelCat = this.cat.label_cat
        this.imageUrl = "https://assets.marketbey.tn/2BeMarket/categories/" + this.cat.photo_cat
      }
    });
  }

  editCategory() {
    if (this.cat.label_cat != "" && this.cat.color_cat != "" && this.cat.order_cat >= 0
      && this.cat.order_cat != "" && this.imageUrl != "" && this.cat.code_cat != '') {

      const formData: any = new FormData();

      if (this.fileToUpload != null) {
        formData.append("photoCat", this.fileToUpload, this.fileToUpload['name']);
      }

      // var category = {
      //   label_cat: label_category.value,
      //   order_cat: order_category.value,
      //   color_cat: color_category.value,
      //   code_cat: code.value
      // }
      this.cat.ancienLabelCat = this.ancienLabelCat
      for (var key in this.cat) {
        formData.append(key, this.cat[key]);
      }

      // if (true) {
      if (confirm('Etes vous sûre?')) {
        this.PrdsServices.editCat(formData).then((res: any) => {
          // console.log("ressssss add cat : ")
          // console.log(res)
          if (res == true) {
            location.href = '/categories';
          } else {
            alert(res.msg);
          }
        });
      }
    } else {
      alert('Catégorie est invalide');
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

}
