import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-commandes-categories',
  templateUrl: './commandes-categories.component.html',
  styleUrls: ['./commandes-categories.component.css']
})
export class CommandesCategoriesComponent implements OnInit {

  idCat: string = ""
  cmdsCat: any = []
  allCats: any = [];
  cat: any = {}
  statusCmds: any = {};

  constructor(private route: ActivatedRoute,
    private CmdsServices: CommandesService,
    private PrdsServices: ProductsService) { }

  ngOnInit(): void {
    this.listCats()
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe(async (params) => {
      this.idCat = params.cat;
    });
    this.listCommandesCat()
    this.getCat()
  }

  selectCat(event) {
    this.idCat = event.target.value
  }

  async listCommandesCat() {
    await this.PrdsServices.allCmdsCat(this.idCat).then((res: any) => {
      // console.log("reeeeeeeeeese : ")
      // console.log(res)
      if (res) {
        this.cmdsCat = res;
      }
    });
  }

  async listCats() {
    await this.PrdsServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }

  async getCat() {
    await this.PrdsServices.oneCat(this.idCat).then((res: any) => {
      if (res) {
        this.cat = res;
      }
    });
  }

  choiceCat() {
    this.listCommandesCat()
    this.getCat()
  }

}
