import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';
import { AddAccountComponent } from 'src/app/components/shared/dialogs/add-account/add-account.component';

@Component({
  selector: 'app-details-fournisseur',
  templateUrl: './details-fournisseur.component.html',
  styleUrls: ['./details-fournisseur.component.css']
})
export class DetailsFournisseurComponent implements OnInit {

  idCommercant: string = ""
  comercant: any = {}
  totalStock: any = {}

  totalPrix: any = 0.0
  totalQuantities: any = 0
  accountComm: any = 0.0
  listAccountsComm: any = []

  constructor(private route: ActivatedRoute,
    private commercantsService: CommercantsService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      this.idCommercant = params.commercant;
    });
    this.getComercant()
    this.totalStockComercant()
    this.statisticComercant()
    this.accountCommercant()
  }

  async getComercant() {
    await this.commercantsService.getComercant(this.idCommercant).then((res: any) => {
      if (res) {
        this.comercant = res;
      }
    });
  }

  async totalStockComercant() {
    await this.commercantsService.totalStockComercant(this.idCommercant).then((res: any) => {
      if (res) {
        this.totalStock = res;
      }
    });
  }

  async statisticComercant() {
    await this.commercantsService.statisticComercant(this.idCommercant).then((res: any) => {
      if (res) {
        res.forEach(element => {
          if (element != null) {
            this.totalQuantities += element.totalQuantity
            this.totalPrix += element.totalValue
          }
        });
      }
    });
  }
  async accountCommercant() {
    this.accountComm = 0.0
    await this.commercantsService.allAccountCommercant(this.idCommercant).then((res: any) => {
      if (res) {
        this.listAccountsComm = res
        res.forEach(element => {
          if (element != null) {
            this.accountComm += element.account_provider
          }
        });
      }
    });
  }
  async addAcompte() {
    const confirmDialog = this.dialog.open(AddAccountComponent, {
      data: {
        commercant: this.idCommercant,
        accountProvider: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        if (data.accountProvider == null || data.accountProvider == "") {
          alert('Acompte du comercant est invalide');
        } else {
          this.commercantsService.addAccountCommercant(data).then((res: any) => {
            console.log(res)
            if (res) {
              alert(res.msg);

              this.accountCommercant()
            }
            // else {
            //   // console.log("erreur");
            //   this.notif.notify('error', "Une erreur s'est produite");
            // }
          });
        }

      }
    });

  }

}
