<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Inventaire</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Inventaire</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Commandes</h4>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="div-form">
                                            <form class="needs-validation" action="" method="post"
                                                onsubmit="return false;"
                                                (onsubmit)="searchInventaireCommandes(dateinCmd, datefinCmd);">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="dateinCmd">Date Début</label>
                                                            <input id="dateinCmd" type="date" class="form-control"
                                                                name="dateinCmd" tabindex="1" #dateinCmd ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="datefinCmd">Date Fin</label>
                                                            <input id="datefinCmd" type="date" class="form-control"
                                                                name="datefinCmd" tabindex="1" #datefinCmd ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-4" style="margin-top: 30px;">
                                                        <div class="form-group">
                                                            <div class="float-right">
                                                                <button type="submit"
                                                                    (click)="searchInventaireCommandes(dateinCmd, datefinCmd)"
                                                                    class="btn btn-primary btn-lg btn-icon icon-righ"
                                                                    id="search" tabindex="4">
                                                                    Statistique commande
                                                                    <!-- <i class="fas fa-search"></i> -->
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="card card-statistic-2">
                                            <div class="card-stats">
                                                <div class="card-stats-items">
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('pending')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsPending?.length}}</div>
                                                      <div class="card-stats-item-label">En attente</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('validated')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsValidated?.length}}</div>
                                                      <div class="card-stats-item-label">Validée</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('prepared')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsPrepared?.length}}</div>
                                                      <div class="card-stats-item-label">Préparé</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('transporting')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsTransporting?.length}}</div>
                                                      <div class="card-stats-item-label">En cours de transport</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('arrived')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsArrived?.length}}</div>
                                                      <div class="card-stats-item-label">Transportée</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('delivered')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsLivred?.length}}</div>
                                                      <div class="card-stats-item-label">Livré</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('returned')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsReturned?.length}}</div>
                                                      <div class="card-stats-item-label">Retournée</div>
                                                    </div>
                                                    <div class="card-stats-item" (click)="displayCmdsByStatus('canceled')">
                                                      <div class="card-stats-item-count">{{statsCmds.allCmdsCanceled?.length}}</div>
                                                      <div class="card-stats-item-label">Annulée</div>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div class="card-icon shadow-primary bg-primary">
                                                <i class="fas fa-archive"></i>
                                              </div>
                                              <div class="card-wrap">
                                                <div class="card-header">
                                                  <h4>Total commandes</h4>
                                                </div>
                                                <div class="card-body">
                                                  {{statsCmds.allCmds?.length}}
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="clearfix mb-3"></div>

                                    <div class="table-responsive table-invoice" *ngIf="listCmds.length > 0">
                                        <table class="table table-striped">
                                          <thead>
                                            <tr>
                                              <th>Référence</th>
                                              <th>Date Création</th>
                                              <th class="text-center">Email<br>Nom Client & Téléphone</th>
                                              <th class="text-center">Produits<br>
                                                Quantité - Nom produit</th>
                                              <th>Ville</th>
                                              <th>Total Commande</th>
                                              <!-- <th>Nombre des produits</th> -->
                                              <!-- <th>Affecté à</th> -->
                                              <th>Status</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let cmd of listCmds">
                                              <!-- <td><input type="checkbox" (change)="addCmdToAffect($event, cmd)"
                                                *ngIf="cmd.etat_cmd=='prepared'"></td> -->
                                              <td><b>{{cmd.ref_cmd}}</b>
                                              </td>
                                              <td>
                                                {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br>
                                                <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                                              </td>
                                              <td class="text-center">{{cmd.facturation.email}} <br>
                                                <div class="d-inline-block ml-1">{{cmd.facturation.nom}}</div><br />
                                                <b>{{cmd.facturation.numtel}}</b>
                                              </td>
                                              <td>
                                                <div *ngFor="let prd of cmd.basket.prdts, let index = index">
                                                  {{prd.quantite}} - {{prd.nom}}
                                                </div>
                                              </td>
                                              <td>{{cmd.facturation.ville}}</td>
                                              <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
                                              <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                                              <!-- <td>{{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}</td> -->
                                              <td>
                                                <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                  {{statusCmds[cmd.etat_cmd].label}}
                    
                                                </div>
                                                <div><span>{{cmd.msg_returned_cmd}}</span></div>
                                              </td>
                    
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Catégories</h4>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="div-form">
                                            <form class="needs-validation" action="" method="post"
                                                onsubmit="return false;"
                                                (onsubmit)="searchInventaireCategories(dateinCat, datefinCat);">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="ref">Catégories</label>
                                                            <select class="form-control selectric"
                                                                (change)="selectChangeCategory($event)">
                                                                <option [value]="cat._id" *ngFor="let cat of allCats">
                                                                    {{cat.label_cat}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="dateinCat">Date Début</label>
                                                            <input id="dateinCat" type="date" class="form-control"
                                                                name="dateinCat" tabindex="1" #dateinCat ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="datefinCat">Date Fin</label>
                                                            <input id="datefinCat" type="date" class="form-control"
                                                                name="datefinCat" tabindex="1" #datefinCat ngModel>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="float-right">
                                                    <button type="submit"
                                                        (click)="searchInventaireCategories(dateinCat, datefinCat)"
                                                        class="btn btn-primary btn-lg btn-icon icon-righ" id="search"
                                                        tabindex="4">
                                                        Valider
                                                        <!-- <i class="fas fa-search"></i> -->
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="clearfix mb-3"></div>

                                    <div class="table-responsive">
                                        <table class="table table-striped-hover">
                                            <tr>
                                                <th>#</th>
                                                <th>Nom de la catégorie</th>
                                                <th class="text-center">Commandes Siteweb</th>
                                                <th class="text-center">Commandes Facebook</th>
                                                <th class="text-center">Total Commandes</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="ingredient-img" style="background-image: url('https://assets.marketbey.tn/2BeMarket/categories/{{inventaireCat.category?.photo_cat}}'); 
                                                  background-position: center; background-repeat: no-repeat; 
                                                  background-size: cover; width: 40px; height: 40px;">
                                                    </div>
                                                </td>
                                                <td>{{inventaireCat.category?.label_cat}}</td>
                                                <td class="text-center">
                                                    <strong>{{inventaireCat.nbrCmdsWebSite}}<br>
                                                        {{inventaireCat.sumCmdsWebsite}} DT</strong>
                                                </td>
                                                <td class="text-center">
                                                    <strong>{{inventaireCat.nbrCmdsTeams}}<br>
                                                        {{inventaireCat.sumCmdsTeams}} DT</strong>
                                                </td>
                                                <td class="text-center">
                                                    <strong>{{inventaireCat?.nbrCmdsTeams +
                                                        inventaireCat.nbrCmdsWebSite}}<br>
                                                        {{inventaireCat.sumCmdsWebsite + inventaireCat.sumCmdsTeams}}
                                                        DT</strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4>Produits</h4>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="div-form">
                                            <form class="needs-validation" action="" method="post"
                                                onsubmit="return false;"
                                                (onsubmit)="searchInventaire(ref, datein, datefin);">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="ref">Référence produit</label>
                                                            <input id="ref" type="text" class="form-control" name="ref"
                                                                tabindex="1" #ref ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="datein">Date Début</label>
                                                            <input id="datein" type="date" class="form-control"
                                                                name="datein" tabindex="1" #datein ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="datefin">Date Fin</label>
                                                            <input id="datefin" type="date" class="form-control"
                                                                name="datefin" tabindex="1" #datefin ngModel>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="ville">Ville</label>
                                                            <select class="form-control selectric"
                                                                (change)="selectChangeVille($event)">
                                                                <option [value]="ville" *ngFor="let ville of villes">
                                                                    {{ville}}</option>
                                                            </select>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="float-right">
                                                    <button type="submit"
                                                        (click)="searchInventaire(ref, datein, datefin)"
                                                        class="btn btn-primary btn-lg btn-icon icon-righ" id="search"
                                                        tabindex="4">
                                                        Valider
                                                        <!-- <i class="fas fa-search"></i> -->
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <!-- <div class="float-right" *ngIf="cmdsListToAffect.length > 0">
                      <form>
                        <div class="input-group">
                          <div class="input-group-append">
                            <a class="btn btn-info" (click)="affectMoreCmds()"><i class="fas fa-search"> Affecté
                                tous</i></a>
                          </div>
                        </div>
                      </form>
                    </div> -->

                                    <div class="clearfix mb-3"></div>

                                    <div class="table-responsive">
                                        <table class="table table-striped-hover">
                                            <tr>
                                                <th>Référence produit</th>
                                                <th>Nom produit</th>
                                                <th>Prix produit</th>
                                                <th>Chiffre d'affaires</th>
                                                <th>Quantité du produit</th>
                                                <th>Total Commande</th>
                                            </tr>
                                            <tr>
                                                <td><b>{{ref.value}} </b></td>
                                                <td>
                                                    {{namePrdt}}
                                                </td>
                                                <td>
                                                    {{prix}} dt
                                                </td>
                                                <td>
                                                    {{totalprix}} dt
                                                </td>
                                                <td>{{nbrePrdts}}</td>
                                                <td>{{totalCmds}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
    </div>
</div>