import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ValidateFournisseurComponent } from 'src/app/components/shared/dialogs/validate-fournisseur/validate-fournisseur.component';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';

@Component({
  selector: 'app-invalid-fournisseur',
  templateUrl: './invalid-fournisseur.component.html',
  styleUrls: ['./invalid-fournisseur.component.css']
})
export class InvalidFournisseurComponent implements OnInit {

  allCommercantsInvalid: any = []

  constructor(
    private commercantsService: CommercantsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.listCommercants();
  }

  async listCommercants() {
    await this.commercantsService.allInvalidCommercants().then((res: any) => {
      if (res) {
        this.allCommercantsInvalid = res;
      }
    });
  }

  async validateCommercant(comm) {
    const confirmDialog = this.dialog.open(ValidateFournisseurComponent, {
      data: {
        idComm: comm._id
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log(data);
        // if (data.etat_comercant == 0) {
        //   alert('Quantité de stock produit est invalide');
        // } else {
        this.commercantsService.updateCommercant(data).then((res) => {
          if (res) {
            this.listCommercants()
          }
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        // }

      }
    });

  }
}
