<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content style="width: 450px;">
    <h1 style="text-align:center"><strong></strong></h1>
    <h2 style="text-align:center;font-size: x-large; color: rgb(194, 192, 192);">Valider le fournisseur</h2>
    <div class="div-form">
        <div class="form-group">
            <label style="font-size: medium; color: white;">Entrer le type de fournisseur :</label>
            <!-- <input type="number" min="1" class="form-control" name="nom_produit"
                (input)="stockChange($event.target.value)"> -->

            <select class="form-control selectric" (change)="typeTransaction($event.target.value)">
                <option value="AMOUNT">MONTANT</option>
                <option value="PERCENT">POUR CENT</option>
            </select>
            <div *ngIf="data.typeTransaction=='PERCENT'">
                <br />
                <label style="font-size: medium; color: white;">Valeur pourcentage :</label>
                <input type="number" min="1" class="form-control" name="value_amount"
                    (input)="valueTransactiont($event.target.value)">
                <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="data.stockPrd<=0">Entrez quantité supérieure à 0
            </mat-error> -->
            </div>
        </div>
    </div>
</div>
<div class="float-right">
    <input type="button" value="NON" [mat-dialog-close]="false" class="btn btn-info">

    <input style="margin:0px 10px;" type="button" value="OUI" [mat-dialog-close]="true" class="btn btn-danger">
</div>