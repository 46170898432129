import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-validate-reclamation',
  templateUrl: './validate-reclamation.component.html',
  styleUrls: ['./validate-reclamation.component.css']
})
export class ValidateReclamationComponent implements OnInit {

  cmdID: string = '';
  reclamation: any = {};

  solutions = [
    "Selectionnez une solution",
    "Remboursement",
    "Echange",
    "Renseignements et conseils",
  ]
  selectedSolution: string = '';
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private CmdsServices: CommandesService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchReclamation();
      // console.log(this.cmd);
    });
  }

  _fetchReclamation() {
    // console.log(cid);
    this.CmdsServices.getOneReclamation(this.cmdID).then((rec) => {
      console.log("rec : ")
      console.log(rec)
      if (rec) {
        this.reclamation = rec;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

  selectChangeSolution(event: any) {
    //update the ui
    this.selectedSolution = event.target.value;
  }

  validerReclamation(recId, comment) {
    this.submitted = true;
    this.CmdsServices.validateReclamation(recId, this.selectedSolution, comment.value).then((rec) => {
      console.log("rec : " + rec)
      if (rec) {
        location.href = '/reclamations-commande';
      }
    })
  }

}
