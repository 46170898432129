<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Teams 2 Be Market</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
              </p> -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-header-uppercase">Liste d'équipe</h4>
                                    <div class="card-header-action">
                                        <a routerLink="/add-user-mb" class="btn btn-danger">Ajouter <i
                                                class="fas fa-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive table-invoice">
                                        <table
                                            class="table table-stripped table-hover table-bordred color-table primary-table">
                                            <tbody>
                                                <tr *ngFor="let user of allTeams">
                                                    <td width="500">
                                                        <div class="row">
                                                            <div class="col-xs-2 margin-right">
                                                                <ngx-avatar class="rounded-circle"
                                                                    name="{{user.prenom_user}} {{user.nom_user}}">
                                                                </ngx-avatar>
                                                            </div>
                                                            <div class="col-xs-8">
                                                                <!-- <div class="media-title">
                                                                  {{user.email_user}}</div> -->
                                                                <h4 class="text-muted m-t-0">{{user.email_user}}
                                                                </h4>
                                                            </div>

                                                        </div>


                                                    </td>
                                                    <td>{{user.prenom_user}} {{user.nom_user}}</td>
                                                    <td>{{user.tel_user}}</td>
                                                    <td>{{user.profiles}}</td>
                                                    <td class="text-right">
                                                        <a title="Modifier"
                                                            class="btn btn-success btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-pencil-alt"></i></a>
                                                        <!-- <a title="Supprimer"
                                                            class="btn btn-danger btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-trash"></i></a>
                                                        <a title="Modifier"
                                                            class="btn btn-warning btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-trash">
                                                                Produits</i></a> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- <footer class="main-footer">
          <div class="footer-left">
            Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
          </div>
          <div class="footer-right">
            2.3.0
          </div>
        </footer> -->
    </div>
</div>