<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau produit</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter une catégorie</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form action="" method="post" enctype="multipart/form-data">

                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Label de la catégorie</label>
                                                        <input type="text" class="form-control" name="label_category"
                                                            ngModel #label_category>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="label_category.value==''">Entrez label de la
                                                            catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Code </label>
                                                        <input type="text" class="form-control" name="code" ngModel
                                                            #code>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="code.value==''">Entrez code de la
                                                            catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <div class="Neon Neon-theme-dragdropbox">
                                                            <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                                                left: 0px; margin-right: auto; margin-left: auto;" name="file"
                                                                id="file" #file accept="image/*"
                                                                (change)="handleFileInput($event.target.files)"
                                                                type="file">
                                                            <div class="Neon-input-dragDrop">
                                                                <div class="Neon-input-inner">
                                                                    <div class="Neon-input-icon" *ngIf="imageUrl==''">
                                                                        <i class="fa fa-file-image-o"></i>
                                                                    </div>
                                                                    <div class="Neon-input-icon" *ngIf="imageUrl!=''">
                                                                        <img [src]="imageUrl" style="width:100px">
                                                                    </div>
                                                                    <div class="Neon-input-text">
                                                                        <h3>Drag&amp;Drop files here</h3> <span
                                                                            style="display:inline-block; margin: 5px 0">or</span>
                                                                    </div><a class="Neon-input-choose-btn blue">Browse
                                                                        Files</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="imageUrl==''">Ajouter l'image de la catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="order_category">Ordere de la catégorie</label>
                                                                <input id="order_category" type="number"
                                                                    class="form-control" name="order_category"
                                                                    #order_category ngModel>
                                                                <mat-error style="color: red;font-weight: bold;"
                                                                    *ngIf="order_category.value<0 || order_category.value==''">
                                                                    L'ordre de la
                                                                    catégorie est invalide
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label>Couleur de la catégorie</label>
                                                                <select class="form-control" name="color_category" ngModel
                                                                    #color_category>
                                                                    <!-- <option value="-">-</option> -->
                                                                    <option value="black">Noir</option>
                                                                    <option value="red">Rouge</option>
                                                                    <option value="blue">Bleu</option>
                                                                    <option value="green">Vert</option>
                                                                    <!-- <option *ngFor="let comm of allCommercants"
                                                                    [value]="comm.commercant._id">
                                                                    {{comm.commercant.nomresponsable_comercant}}</option> -->
                                                                </select>
                                                                <mat-error style="color: red;font-weight: bold;"
                                                                    *ngIf="color_category.value==''">Choisir le couleur de
                                                                    la
                                                                    catégorie
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="button"
                                                    (click)="addCategory(label_category, color_category, order_category, code)"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter catégorie
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>