<div>

  <head>
    <meta charset="utf-8" />
    <title>Facture</title>
  </head>

  <body>
    <header class="clearfix">
      <div id="logo">
        <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id" [errorCorrectionLevel]="'M'">
        </qrcode>
      </div>
      <div id="company">
        <h2 class="name">2 Be Market</h2>
        <!-- <div>Rue de l'energie atomique</div> -->
        <div>CHARGUIA, TUNIS-CARTHAGE</div>
        <!-- <div>(+216) 27 305 100</div> -->
        <!-- <div>
          <a href="mailto:marketbey.stat@gmail.com">contact@marketbey.tn</a>
        </div> -->
      </div>
    </header>
    <main>
      <div id="details" class="clearfix">
        <div id="client">
          <div class="to">FACTURE POUR:</div>
          <h3 class="name">{{cmd.facturation.nom}}</h3>
          <div class="to">
            <h4><b>Matricule fiscale:</b> {{cmd.facturation.matricule}}</h4>
            <h4><b>Adresse:</b> {{cmd.facturation.adresse}} {{cmd.facturation.ville}}</h4>
            <h4><b>Email: </b>{{cmd.facturation.email}}</h4>
            <h4> <b>Tél: </b>{{cmd.facturation.numtel}}</h4>
          </div>
          <div class="address">
            
          </div>
          <div class="email">
            
          </div>
          <div class="email">
            
          </div>
        </div>
        <div id="invoice">
          <h1>Bon de Livraison: {{cmd.ref_cmd}}</h1>
          <div class="date">Date: {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}</div>
        </div>
      </div>
      <table border="0" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th class="no">#</th>
            <th class="desc">Produit</th>
            <th class="unit">PRIX UNITAIRE</th>
            <th class="qty">QUANTITE</th>
            <th class="total">MONTANT</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let prd of cmd.basket.prdts, let index = index">
            <td class="no">{{index+1}}</td>
            <td class="desc">
              <h3>{{prd.nom}}</h3>
              <div *ngIf="prd.variation_choisi!=null">
                <span *ngFor="let variation of prd?.variation_choisi | objNgFor">
                  <div><b> {{variation.value}} </b></div>
                </span>
              </div>
              <!-- {{prd.dsp}} -->
            </td>
            <td class="unit">{{prd.prix_unitaire}} TND</td>
            <td class="qty">{{prd.quantite}}</td>
            <td class="total">{{prd.stotal}} TND</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">Prix article(s)</td>
            <td>{{cmd.total_ca_cmd | number:'0.3'}} <b>TND</b></td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">Frais de livraison</td>
            <td>{{cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td colspan="2">Montant total</td>
            <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
          </tr>
        </tfoot>
      </table>
      <div id="notices">
        <div class="notice"> Mode de livraison: {{cmd.livraison_cmd.moyen_livraison}}
          <br />{{cmd.livraison_cmd.pcollect_details.adresse_pcollect}}
        </div>
      </div>
      <div id="thanks">A très bientôt!</div>
      <div id="notices">
        <div class="notice">2 Be Market VOUS REMERCIE POUR VOTRE CONFIANCE.</div>
      </div>
    </main>
    <!-- <footer>
      ********************************
    </footer> -->
  </body>
</div>>