<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <div class="ingredient-img margin-right" style="background-image: url('https://assets.marketbey.tn/2BeMarket/categories/{{cat.photo_cat}}'); 
                            background-position: center; background-repeat: no-repeat; 
                            background-size: cover; width: 40px; height: 40px;">
                    </div>
                    <h1>{{cat.label_cat}}</h1>

                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
              </p> -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <!-- <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a class="nav-link active">Tous <span
                                                    class="badge badge-white">fffff</span></a>
                                        </li>

                                    </ul> -->
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                produits :
                                                {{cat.nbrPrds}}
                                            </h3>
                                            <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                commandes :
                                                {{cat.nbrCmds}}
                                            </h3>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <div class="input-group text-right">
                                                    <!-- <input type="text" class="form-control" id="raisonsocial" name="raisonsocial" placeholder="Raison social"> -->
                                                    <!-- <label for="cat_produit">Catégorie : </label> -->
                                                    <SELECT class="form-control" name="cat_produit[]" id="cat_produit"
                                                        (change)="selectCat($event)">
                                                        <option value="">-- Veuillez choisir une catégorie --</option>
                                                        <option *ngFor="let cat of allCats" [value]="cat._id">
                                                            {{cat.label_cat}}</option>
                                                    </SELECT>
                                                    <button type="submit" (click)="choiceCat()"
                                                        class="btn btn-primary btn-lg btn-icon icon-righ" id="search"
                                                        tabindex="4">
                                                        <!-- Recherche -->
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-12">
                            <div class="card">
                                <!-- <div class="card-header">
                                    <h4>Dernières commandes</h4>
                                    <div class="card-header-action">
                                        <a href="#" class="btn btn-danger">View More <i
                                                class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div> -->
                                <div class="card-body">
                                    <div class="table-responsive table-invoice">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Référence</th>
                                                    <th>Date Création</th>
                                                    <th>Nom Client & Téléphone</th>
                                                    <th>Email</th>
                                                    <th>Ville</th>
                                                    <th>Total Commande</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let cmd of cmdsCat">
                                                    <td><b>{{cmd.ref_cmd}}</b>
                                                    </td>
                                                    <td>
                                                        {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}
                                                    </td>
                                                    <td>
                                                        <div class="d-inline-block ml-1">{{cmd.facturation.nom}}</div>
                                                        <br />
                                                        <b>{{cmd.facturation.numtel}}</b>
                                                    </td>
                                                    <td>{{cmd.facturation.email}}</td>
                                                    <td>{{cmd.facturation.ville}}</td>
                                                    <td>{{cmd.total_ca_cmd +
                                                        cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
                                                    <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                                                    <!-- <td>{{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}</td> -->
                                                    <td>
                                                        <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                            {{statusCmds[cmd.etat_cmd].label}}

                                                        </div>
                                                        <div><span>{{cmd.msg_returned_cmd}}</span></div>
                                                    </td>
                                                    <td>
                                                        <a [routerLink]="['/details-commande']"
                                                            [queryParams]="{ cmd: cmd._id }" class="btn btn-warning btn-action trigger--fire-modal-1">
                                                            <i class="fa fa-info-circle"></i> Détails</a>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</div>