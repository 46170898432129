import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ProductsService } from '../../../../services/products/products.service';

@Component({
  selector: 'app-validation-dialog',
  templateUrl: './validation-dialog.component.html',
  styleUrls: ['./validation-dialog.component.css']
})
export class ValidationDialogComponent implements OnInit {

  prdsList: any = [];
  baseURL: String = environment.baseImagesURL;
  invalidStock = true;

  constructor(public dialogRef: MatDialogRef<ValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productServices: ProductsService) { }

  ngOnInit(): void {
    this.listProducts()
  }

  async listProducts() {
    this.data.cmd.basket.prdts.forEach(async (element) => {
      var p:any = await this.productServices.getProduct(element.idprdt)
      // console.log("ppppppppp")
      // console.log(p)
      // console.log("p.stock_reelle")
      // console.log(p.prd.stock_reelle)
      // console.log("p.stock_reelle!=undefined")
      // console.log(p.prd.stock_reelle!=undefined)
      // console.log("p.prd.stock_reelle<=0")
      // console.log(p.prd.stock_reelle<=0)
      this.invalidStock = this.invalidStock && (p.prd.stock_reelle!=undefined && p.prd.stock_reelle>=0)
      this.prdsList.push(p)
    });
  }

}
