<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Liste des reclamations</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a href="#">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les reclamations</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <ul class="nav nav-pills float-right">
                                        <li class="nav-item">
                                            <a [ngClass]="(tagActive==='EN_COURS')?'nav-link active':'nav-link'"
                                                (click)="allReclamations('EN_COURS')">En
                                                cours <span
                                                    [ngClass]="(tagActive==='EN_COURS')?'badge badge-white':'badge badge-primary'">{{lengthRcmsEnCours}}</span></a>
                                        </li>
                                        <li class="nav-item">
                                            <a [ngClass]="(tagActive==='EN_SORTIE')?'nav-link active':'nav-link'"
                                                (click)="allReclamations('EN_SORTIE')">Sortie
                                                <span
                                                    [ngClass]="(tagActive==='EN_SORTIE')?'badge badge-white':'badge badge-primary'">{{lengthRcmsSortie}}</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card">
                                <div class="budget-price justify-content-center" *ngIf="reclamationsList.length === 0">
                                    <h3>Aucune reclamation {{tagActive}} </h3>
                                </div>
                                <div class="card-body" *ngIf="reclamationsList.length > 0">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <tr>

                                                <th>Actions</th>
                                                <th>Référence commande</th>
                                                <th>Date Création</th>
                                                <th>Client</th>
                                                <th>Raison</th>
                                                <th>Solution</th>
                                                <th>Status</th>

                                            </tr>
                                            <tr *ngFor="let rec of reclamationsList">

                                                <td *ngIf="tagActive==='EN_COURS'">
                                                    <div class="dropdown d-inline mr-2">
                                                        <button class="btn btn-primary dropdown-toggle" type="button"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">Actions</button>
                                                        <div class="dropdown-menu">
                                                            <a [routerLink]="['/validate-reclamation']"
                                                                [queryParams]="{ cmd: rec.commande._id }"
                                                                class="dropdown-item">
                                                                <i class="fa fa-check"></i>Valider</a>

                                                        </div>
                                                    </div>
                                                </td>
                                                <td *ngIf="tagActive==='EN_SORTIE'">
                                                    <button [routerLink]="['/view-reclamation']"
                                                    [queryParams]="{ cmd: rec.commande._id }"
                                                        class="btn btn-warning btn-icon icon-left"><i
                                                            class="fas fa-eye"></i>
                                                        Détail</button>
                                                </td>
                                                <td><b>{{rec.commande.ref_cmd}}</b>
                                                </td>
                                                <td>
                                                    {{rec.date_create_reclamation | date:'dd/MM/yyyy'}}
                                                </td>
                                                <td>
                                                    <div class="d-inline-block ml-1">{{rec.commande.facturation.nom}}<br/>
                                                        <strong>{{rec.commande.facturation.numtel}}</strong>
                                                    </div>
                                                </td>
                                                <td>{{rec?.raison_reclamation}}</td>
                                                <td>{{rec?.solution_reclamation}}</td>
                                                <td>
                                                    <div class="badge badge-info">
                                                        {{rec.status_reclamation}}
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>