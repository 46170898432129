import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-view-produit',
  templateUrl: './view-produit.component.html',
  styleUrls: ['./view-produit.component.css']
})
export class ViewProduitComponent implements OnInit {

  idProduct: string = ""
  product: any = {}
  allCats: any = [];

  constructor(private route: ActivatedRoute,
    private productServices: ProductsService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idProduct = params.prd;
      this.getProduct()
      
    this.listCats()
    });
  }
  async listCats() {
    await this.productServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }
  async getProduct() {
    await this.productServices.getProduct(this.idProduct).then((res: any) => {
      console.log("reeees : ")
      console.log(res)
      if (res) {
        this.product = res;
      }
    });

    console.log("this.product.tags_produit")
    console.log((this.product.prd.tags_produit))
  }

}
